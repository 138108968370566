import React, { useRef } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import InnerLayout from "../components/inner-layout"

import CoursesImg from "../images/courses.jpg"
import CoursesImg2 from "../images/annie.jpg"

const FusedGlass = () => {
  const coursesRef = useRef(null)

  const scrollToSection = (e, section) => {
    e.preventDefault()

    // offset sticky header height
    const sectionTop = section.current.offsetTop

    if (typeof window !== "undefined") {
      window.scrollTo({
        top: sectionTop,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  return (
    <Layout>
      <SEO title="Glass Blowing Courses" />
      <InnerLayout>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h1>Glassblowing Courses</h1>
              <p>
                Hot Glass Courses taster half day and one day courses, will be
                available from the end of October.{" "}
              </p>

              <p>
                You may register your interest at any time, and preferred time
                to be advised in advance of publication on the exact course
                dates available.
              </p>

              <p>
                Half-day £150.00
                <br />
                Full Day £300.00
              </p>
              <Link to="/contact-us" className="btn btn-primary mt-2">
                Contact us
              </Link>

              {/* <a
                href="#"
                onClick={e => scrollToSection(e, coursesRef)}
                className="btn btn-primary "
              >
                View April Courses
              </a> */}
            </Col>
            <Col md={6}>
              <div className="p-2 p-md-4 mt-4 mt-md-0">
                <img
                  src={CoursesImg}
                  className="img-fluid"
                  alt="Glass Blowing Course By Tim Simon"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </InnerLayout>
      <Container className="py-5">
        <Row className="align-items-center">
          <Col md={6}>
            <img
              src={CoursesImg2}
              className="img-fluid"
              alt="Glass Blowing Course By Tim Simon"
            />
          </Col>
          <Col md={6}>
            <p>Meantime. To whet your creative instincts, Try something new</p>

            <ul>
              <li>Make your own heirlooms</li>
              <li>The best gifts are handmade from the heart.</li>
              <li>
                Expert tuition and guidance provided to see your project to
                completion.
              </li>
              <li>Tuition given in fused glass for panels or bowls.</li>
              <li>
                Stained Glass – Experiment with line and colour to make door or
                window panels, suncatchers.
              </li>
              <li>
                hese courses are designed to help you succeed in being creative,
                you just have to try. There is no failing just the opportunity
                to make something truly personal.
              </li>
              <li>
                To embark on what could be a new adventure, Please call the
                studio for an appointment to suit you. Outline your aims and any
                previous experience if relevant.
              </li>
            </ul>

            <p>
              Half-day £45.00
              <br />
              Full Day £75.00
            </p>

            <p>
              This includes mentoring , instruction , use of equipment and
              firing where appropriate. Materials charge extra depending on the
              project.
            </p>
          </Col>
        </Row>
      </Container>

      {/* <Container className="py-5" ref={coursesRef}>
        <Row>
          <Col md={8} className="mx-auto">
            <h2>April 2022</h2>
            <div className="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>&nbsp;</th>
                    <th>Places per Session</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>9.4.22</td>
                    <td>Full days</td>
                    <td>2</td>
                    <td>300.00 each</td>
                  </tr>
                  <tr>
                    <td>10.4.22</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>11.4.22</td>
                    <td>Half day am</td>
                    <td>2</td>
                    <td>150.00 each</td>
                  </tr>
                  <tr>
                    <td>11.4.22</td>
                    <td>Half day pm</td>
                    <td>2</td>
                    <td>150.00 each</td>
                  </tr>
                  <tr>
                    <td>13.4.22</td>
                    <td>Full Day</td>
                    <td>2</td>
                    <td>300.00 each</td>
                  </tr>
                  <tr>
                    <td>14.4.22</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>15.4.22</td>
                    <td>Half day am</td>
                    <td>2</td>
                    <td>150.00 each</td>
                  </tr>
                  <tr>
                    <td>15.4.22</td>
                    <td>Half day pm</td>
                    <td>2</td>
                    <td>150.00 each</td>
                  </tr>
                  <tr>
                    <td>17.4.22</td>
                    <td>Full days</td>
                    <td>2</td>
                    <td>300.00 each</td>
                  </tr>
                  <tr>
                    <td>18.4.22</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>19.4.22</td>
                    <td>Half day am</td>
                    <td>2</td>
                    <td>150.00 each</td>
                  </tr>
                  <tr>
                    <td>19.4.22</td>
                    <td>Half day pm</td>
                    <td>2</td>
                    <td>150.00 each</td>
                  </tr>
                  <tr>
                    <td>21.4.22</td>
                    <td>Full days</td>
                    <td>2</td>
                    <td>300.00 each</td>
                  </tr>
                  <tr>
                    <td>22.4.22</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>23.4.22</td>
                    <td>Half day am</td>
                    <td>2</td>
                    <td>150.00 each</td>
                  </tr>
                  <tr>
                    <td>23.4.22</td>
                    <td>Half day pm</td>
                    <td>2</td>
                    <td>150.00 each</td>
                  </tr>
                  <tr>
                    <td>25.4.22</td>
                    <td>Full days</td>
                    <td>2</td>
                    <td>300.00 each</td>
                  </tr>
                </tbody>
              </table>
              <p className="mt-3 text-center">
                Please contact the studio for booking a convenient time.
              </p>
              <p className="mt-2 text-center">
                <Link to="/contact-us" className="btn btn-primary">
                  Contact us
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container> */}
    </Layout>
  )
}

export default FusedGlass
